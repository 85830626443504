import * as React from 'react';
import { useCallback, useState } from 'react';
import { IProductLabels } from 'components/Product/Search';
import { IProductResponse } from 'components/Product/Results';
import ButtonIcon from 'veho-ui/lib/Components/ButtonIcon';
import { extractErrors } from '../../../helpers/request/extractErrors';
import OverlayConfirm from 'components/overlay/OverlayConfirm';
import { useMutation } from 'redux-query-react';
import removeWishlistRequest from 'components/Product/requests/removeWishlistRequest';
import Toaster from 'veho-ui/lib/Components/Toaster';
import { request } from 'data/requests/request';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';

interface IProps {
    isCheckout?: boolean;
    isWishlist?: boolean;
    product: IProductResponse;
    labels: IProductLabels;
    setError?: (error) => void;
    quoteId: string;
}

const Remove: React.FunctionComponent<IProps> = (props) => {
    const { labels, product, setError, isWishlist, isCheckout, quoteId } = props;
    const [quoteItemId, setQuoteItemId] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [{}, removeWishlistProduct] = useMutation((product) => removeWishlistRequest(product));

    const [{}, cartRequest] = useMutation((data, id, method) =>
        request({
            method: method,
            type: 'response',
            notApi: false,
            url: isLoggedIn() ? `carts/mine/items/${id}` : `guest-carts/${quoteId}/items/${id}`,
            data,
        }),
    );

    const removeItemFromCart = (id) => {
        setQuoteItemId(id);
        setShowConfirm(true);
    };

    const handleDelete = useCallback(async () => {
        if (isCheckout) {
            const response = await cartRequest({} as any, quoteItemId, 'DELETE' as any);
            if (response.status === 200) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
            } else {
                const errors = extractErrors(response);
                if (errors && errors.error) {
                    if (setError) {
                        setError(errors.error);
                    }
                }
            }
        }

        if (isWishlist) {
            const response = await removeWishlistProduct(product);
            if (response.status === 200) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
            }

            Toaster.addToast({
                intent: response.status === 200 ? 'success' : 'danger',
                text: response.body.message,
                asHtml: true,
            });
        }
    }, [quoteItemId]);

    const handleClose = useCallback(async () => {
        setShowConfirm(false);
    }, []);

    return (
        <React.Fragment>
            {showConfirm && (
                <OverlayConfirm
                    title={labels.confirmTitle}
                    description={labels.confirmDescription}
                    buttonCancel={labels.confirmButtonCancel}
                    buttonOk={labels.confirmButtonOk}
                    onAccept={handleDelete}
                    onClose={handleClose}
                />
            )}
            <ButtonIcon
                intent="default"
                icon="close"
                onClick={(e) => {
                    e.preventDefault();
                    removeItemFromCart(product.quoteItemId);
                }}
            />
        </React.Fragment>
    );
};

export default Remove;
