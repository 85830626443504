import * as React from 'react';
import LayoutForm from 'veho-ui/lib/Layouts/LayoutForm';
import FormRow from 'veho-ui/lib/Components/FormRow';
import FormRowGroup from 'veho-ui/lib/Components/FormRowGroup';
import ControlTextarea from 'veho-ui/lib/Components/ControlTextarea';
import Button from 'veho-ui/lib/Components/Button';
import Dropzone from 'veho-ui/lib/Components/Dropzone';
import RenderHTML from 'veho-ui/lib/Components/RenderHTML';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import productsRequest from 'components/Product/requests/productsRequest';
import Toaster from 'veho-ui/lib/Components/Toaster';
import Results, { IProductResponse } from 'components/Product/Results';
import Callout from 'veho-ui/lib/Components/Callout';
import { SupportedFileTypesEnum } from 'components/Product/enums/SupportedFileTypes';
import Papa from 'papaparse';
import { empty } from '../../helpers/empty';
import isLoggedIn from '../../helpers/auth/isLoggedIn';
import { getFileType, isSupported } from 'components/Product/helpers/fileParseHelper';

export interface ISearchProps {
    config: {
        currencyCode: string;
        labels: IProductLabels;
    };
}

export interface IProductLabels {
    loggedInDescription: string;
    guestDescription: string;
    searchPlaceholder: string;
    searchButtonTitle: string;
    uploadButtonTitle: string;
    noResult: string;
    inStock: string;
    replacementProduct: string;
    noProductWithCode: string;
    toBeOrdered: string;
    deliveryDays: string;
    together: string;
    amount: string;
    toCart: string;
    toWishlist: string;
    noStock: string;
    pieceShort: string;
    confirmTitle: string;
    confirmDescription: string;
    confirmButtonCancel: string;
    confirmButtonOk: string;
    addedToWishlistSuccess: string;
    removeFromWishlistSuccess: string;
    toolbar: IToolbarLabels;
    fileUpload: IFileUploadLabels;
}

export interface IToolbarLabels {
    defaultWarehouse: string;
    showPrices: string;
    tax: string;
    withoutTax: string;
}

export interface IFileUploadLabels {
    somethingWrong: string;
    unsupportedFileFormat: string;
}

const Search: React.FunctionComponent<ISearchProps> = (props) => {
    const { config } = props;
    const [keywords, setKeywords] = useState('');
    const [mappedQuantities, setMappedQuantities] = useState({});
    const [products, setProducts] = useState([]);
    const [triggerQuery, setTriggerQuery] = useState(false);

    const [{ isFinished, isPending }, queryProducts] = useMutation(() => productsRequest(keywords));

    const makeQuery = useCallback(async () => {
        setProducts([]);
        const response = await queryProducts();
        if (response.status === 200) {
            const result = response.body?.response;
            if (result) {
                result.map((product: IProductResponse, index) => {
                    if (mappedQuantities[product.sku]) {
                        product.buyAmount = mappedQuantities[product.sku];
                        result[index] = product;
                    }
                });
            }
            setProducts(result ?? []);
        } else {
            Toaster.addToast({
                intent: 'danger',
                text: response.body.message,
                asHtml: true,
            });
        }
    }, [keywords, mappedQuantities]);

    // useCallback holds the wrong ref for making query straight from other function,
    // useEffect will reset ref in order for state params to update
    useEffect(() => {
        if (triggerQuery) {
            makeQuery();
            setTriggerQuery(false);
        }
    }, [triggerQuery, makeQuery]);

    const setKeywordToLocalStorage = (inputValue: string) => {
        localStorage.setItem('keywords', inputValue);
    };

    useEffect(() => {
        const keywords = localStorage.getItem('keywords');
        if (keywords && !empty(keywords)) {
            setKeywords(keywords);
        }
    }, []);

    const handleErrors = (results) => {
        if (results.errors && results.errors.length) {
            results.errors.forEach((error: string) => {
                Toaster.addToast({
                    intent: 'danger',
                    text: error,
                    asHtml: true,
                });
            });
            return true;
        }

        return false;
    };

    const mapResult = (mappedQuantitiesLocal, keywordsLocal, productSku, productAmount) => {
        if (productAmount && !isNaN(parseFloat(productAmount)) && productSku) {
            mappedQuantitiesLocal[productSku] = parseFloat(productAmount);
        }

        if (productSku) {
            keywordsLocal.push(productSku);
        }
    };

    const handleUploadContent = (f: File, contents: string | ArrayBuffer) => {
        const fileType: string = getFileType(f);
        const mappedQuantitiesLocal: {} = {};
        const keywordsLocal: string[] = [];
        // CSV parse
        if (typeof contents === 'string' && fileType === SupportedFileTypesEnum.csv) {
            Papa.parse(contents, {
                header: false,
                skipEmptyLines: true,
                complete: (results) => {
                    if (handleErrors(results)) {
                        return;
                    }

                    results.data.map((rowElements: string[]) => {
                        const productSku = rowElements[0] ? rowElements[0] : null;
                        const productAmount = rowElements[1] ? rowElements[1] : null;

                        mapResult(mappedQuantitiesLocal, keywordsLocal, productSku, productAmount);
                    });
                    setMappedQuantities(mappedQuantitiesLocal);
                    setKeywords(keywordsLocal.join(','));
                    setKeywordToLocalStorage(keywordsLocal.join(','));
                    setTriggerQuery(true);
                },
            });
        } // EPC parse
        else if (typeof contents === 'string' && fileType === SupportedFileTypesEnum.epc) {
            const lines: string[] = contents.split('\n');
            // remove first empty line
            lines.splice(0, 1);
            const newContent: string = lines.join('\n');

            Papa.parse(newContent, {
                header: false,
                skipEmptyLines: true,
                delimiter: '|',
                complete: (results) => {
                    if (handleErrors(results)) {
                        return;
                    }

                    results.data.map((rowElements: string[]) => {
                        const productSku = rowElements[0] ?? null;
                        const productAmount = rowElements[2] ?? null;

                        mapResult(mappedQuantitiesLocal, keywordsLocal, productSku, productAmount);
                    });

                    setMappedQuantities(mappedQuantitiesLocal);
                    setKeywords(keywordsLocal.join(','));
                    setKeywordToLocalStorage(keywordsLocal.join(','));
                    setTriggerQuery(true);
                },
            });
        } else {
            Toaster.addToast({
                intent: 'danger',
                text: config.labels.fileUpload.somethingWrong,
                asHtml: true,
            });
        }
    };

    const handleFileUpload = (acceptedFiles: File[]) => {
        acceptedFiles.map((f: File) => {
            const reader = new FileReader();

            if (isSupported(f)) {
                reader.readAsText(f);

                reader.onload = (e) => {
                    if (e.target && e.target.result) {
                        const contents = e.target.result;
                        handleUploadContent(f, contents);
                    } else {
                        Toaster.addToast({
                            intent: 'danger',
                            text: config.labels.fileUpload.somethingWrong,
                            asHtml: true,
                        });
                    }
                };
            } else {
                Toaster.addToast({
                    intent: 'danger',
                    text: config.labels.fileUpload.unsupportedFileFormat,
                    asHtml: true,
                });
            }
        });

        if (!acceptedFiles.length) {
            Toaster.addToast({
                intent: 'danger',
                text: config.labels.fileUpload.unsupportedFileFormat,
                asHtml: true,
            });
        }
    };

    return (
        <React.Fragment>
            <div className="box-search">
                <LayoutForm>
                    <FormRow
                        description={
                            isLoggedIn() ? (
                                <React.Fragment>
                                    <RenderHTML nowrapper={true} html={config.labels.loggedInDescription} />
                                </React.Fragment>
                            ) : (
                                <RenderHTML nowrapper={true} html={config.labels.guestDescription} />
                            )
                        }
                    >
                        <FormRowGroup>
                            <ControlTextarea
                                value={keywords}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }
                                }}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        makeQuery();
                                    }
                                }}
                                onChange={(e) => {
                                    setKeywords(e.target.value);
                                    setKeywordToLocalStorage(e.target.value);
                                }}
                                placeholder={config.labels.searchPlaceholder}
                            />
                            <Button
                                title={config.labels.searchButtonTitle}
                                intent="primary"
                                icon="search"
                                loading={isPending}
                                onClick={() => makeQuery()}
                            />
                            {isLoggedIn() && (
                                <Dropzone
                                    buttonProps={{
                                        title: `${config.labels.uploadButtonTitle}`,
                                        intent: 'success',
                                        icon: 'checklist',
                                    }}
                                    accept={[
                                        '.csv',
                                        '.xfr',
                                        'text/csv',
                                        'application/vnd.ms-excel',
                                        'application/csv',
                                        'text/x-csv',
                                        'application/x-csv',
                                        'text/comma-separated-values',
                                        'text/x-comma-separated-values',
                                    ]}
                                    onFilesAdded={(acceptedFiles, rejectedFiles) => {
                                        handleFileUpload(acceptedFiles);
                                    }}
                                />
                            )}
                        </FormRowGroup>
                    </FormRow>
                </LayoutForm>
            </div>
            {products.length > 0 && (
                <React.Fragment>
                    <Results currencyCode={config.currencyCode} labels={config.labels} products={products} />
                </React.Fragment>
            )}
            {products.length === 0 && isFinished && <Callout intent="warning">{config.labels.noResult}</Callout>}
        </React.Fragment>
    );
};

export default Search;
