import * as React from 'react';

import 'veho-ui/lib/Applications/Checkout/Components/Banklinks/styles.scss';
import { IAgreementItem } from '../../../interfaces/payment/IAgreementsResponse';
import FormRow from 'veho-ui/lib/Components/FormRow/index';
import CheckboxWithLabel from 'veho-ui/lib/Components/CheckboxWithLabel/index';
import LayoutForm from 'veho-ui/lib/Layouts/LayoutForm/index';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { empty } from '../../../helpers/empty';
import {ITerms} from "components/Checkout/components/checkout/Payment/PaymentBlock";
import useOverlays from "components/Checkout/components/overlay/Overlay";
import AgreementsOverlay from "components/Checkout/components/checkout/Payment/Overlay/AgreementsOverlay";

interface IAgreementProps {
    terms: ITerms[];
    setTerms: (terms: ITerms[]) => void;
    errors: boolean;
}

const Agreements = (props: IAgreementProps) => {
    const {terms, setTerms, errors} = props;
    const [stateChange, setStateChange] = useState(false);
    const { t } = useTranslation();
    const {openOverlay} = useOverlays();

    return (
        <LayoutForm layout="vertical">
            {window.agreements?.totalRecords > 0 && (
                window.agreements.items.map((agreement: IAgreementItem, index) => {
                        const agreementItem = terms.find(item => item.id === agreement.agreement_id);
                        const termsAccepted = !!(agreementItem && agreementItem.accepted);
                        const changeItem = (id) => {
                            terms.forEach(term => {
                                if (term.id === id) {
                                    term.accepted = !term.accepted;
                                }
                            });
                            setTerms(terms);
                            setStateChange(!stateChange);
                        };
                        const agreementIndex = `agreements-${agreement.agreement_id}`;
                        return (
                            <FormRow key={index} required={true} error={errors && !termsAccepted && t('This is a required field')}>
                                <CheckboxWithLabel
                                    label={(
                                        <a href={'#'} onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            openOverlay(agreementIndex, true);
                                        }}>{agreement.checkbox_text}</a>
                                    )}
                                    checked={termsAccepted}
                                    onChange={() => changeItem(agreement.agreement_id)}
                                    testId={'payment-terms-checkbox'}
                                />
                                <AgreementsOverlay
                                    key={`overlay-${index}`}
                                    input={agreement.content}
                                    index={agreementIndex}
                                    asHtml={!empty(agreement.is_html)}
                                />
                            </FormRow>
                        );
                    }
                )
            )}
        </LayoutForm>
    );
};

export default Agreements;
