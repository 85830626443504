import * as React from 'react';
import ListProgress from 'veho-ui/lib/Applications/Checkout/Components/ListProgress';
import ListProgressItem from 'veho-ui/lib/Applications/Checkout/Components/ListProgressItem';
import LayoutSplit from 'veho-ui/lib/Layouts/LayoutSplit';
import LayoutSplitColumn from 'veho-ui/lib/Layouts/LayoutSplitColumn';
import { useState } from 'react';
import ShippingMethods from '../checkout/Shipping/ShippingMethods';
import ShippingMethodAdditional from '../checkout/Shipping/Method/Additional';
import AddressBlock from '../checkout/Shipping/AddressBlock';
import PaymentBlock from '../checkout/Payment/PaymentBlock';
import { useTranslation } from 'react-i18next';
import { IQuote } from '../../interfaces/checkout/quote/IQuote';
import { useEffect } from 'react';
import { ICart } from '../../interfaces/checkout/cart/cartType';
import { ICustomer, IGuestAddress } from '../../interfaces/checkout/customer/ICustomer';
import {IShippingMethod} from "components/Checkout/interfaces/checkout/shipping/IShippingMethod";

export interface IProps {
    quote: IQuote;
    hasMedicine: boolean;
    cart: ICart | undefined;
}

export const SHIPPING_METHOD_STEP = 1;
export const SHIPPING_DATA_STEP = 2;
export const PAYMENT_STEP = 3;
let customerSelectedAddressId: number | null;
let automaticOverride = false;

export interface ICartContext {
    cartItems: ICartItem[];
    customer: ICustomer | null;
}
export interface ICartItem {
    sku?: string;
    name: string;
    price: number;
    qty: number;
}
export const CartContext = React.createContext<ICartContext>({ cartItems: [], customer: null });

const ShippingContent = (props: IProps) => {
    const {quote, hasMedicine, cart} = props;
    const customer = quote?.customer || null;
    const quoteId = quote?.id || null;
    const [currentStep, setCurrentStep] = useState(1);
    const [email, setEmail] = useState('');
    const [newAddressFields, setNewAddressFields] = useState<IGuestAddress>();
    const [selectedAddress, setSelectedAddress] = useState(null);
    const selectedAssignment = quote?.extension_attributes?.shipping_assignments?.[0];
    const [selectedMethod, selectMethod] = useState<IShippingMethod>();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const { t } = useTranslation();
    const cartItems = cart?.items.map((item) => {
        return {
            sku: item.extension_attributes?.sku,
            name: item.name,
            price: item.price_incl_tax,
            qty: item.qty,
        };
    });

    const resetAction = () => {
        setCurrentStep(SHIPPING_METHOD_STEP);
    };

    useEffect(() => {
        customerSelectedAddressId = selectedAssignment?.shipping?.address?.customer_address_id || null;
        // If address is selected, shortcut some steps.
        if (customerSelectedAddressId && currentStep < SHIPPING_DATA_STEP && !automaticOverride) {
            automaticOverride = true;
            setCurrentStep(SHIPPING_DATA_STEP);
        }
    });

    return (
        <CartContext.Provider value={{ cartItems: cartItems ?? [], customer }}>
            <ListProgress>
                <ListProgressItem
                    completed={currentStep > SHIPPING_METHOD_STEP}
                    current={currentStep === SHIPPING_METHOD_STEP}
                    title={t('checkout.Select shipping method')}
                >
                    <LayoutSplit>
                        <LayoutSplitColumn>
                            <ShippingMethods
                                selectedMethod={selectedMethod}
                                selectMethod={selectMethod}
                                setCurrentStep={setCurrentStep}
                                selectedMethodCode={selectedAssignment?.shipping.method}
                            />
                        </LayoutSplitColumn>
                        <LayoutSplitColumn>
                            <ShippingMethodAdditional
                                selectedMethod={selectedMethod}
                                setCurrentStep={setCurrentStep}
                                resetAction={resetAction}
                                quoteId={quoteId}
                                selectedAssignment={selectedAssignment}
                                currentStep={currentStep}
                            />
                        </LayoutSplitColumn>
                    </LayoutSplit>
                </ListProgressItem>
                <ListProgressItem
                    completed={currentStep > SHIPPING_DATA_STEP}
                    current={currentStep === SHIPPING_DATA_STEP}
                    title={t('checkout.Contact information')}
                >
                    <AddressBlock
                        customer={customer}
                        selectedMethod={selectedMethod}
                        selectedAddressId={customerSelectedAddressId}
                        proceedAction={() => setCurrentStep(PAYMENT_STEP)}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setPaymentMethods={setPaymentMethods}
                        email={email}
                        setEmail={setEmail}
                        newAddressFields={newAddressFields}
                        setNewAddressFields={setNewAddressFields}
                        setSelectedAddress={setSelectedAddress}
                    />
                </ListProgressItem>
                <ListProgressItem
                    completed={currentStep > PAYMENT_STEP}
                    current={currentStep === PAYMENT_STEP}
                    title={t('checkout.Review & Payments')}
                >
                    <PaymentBlock
                        selectedMethod={selectedMethod}
                        customer={customer}
                        paymentMethods={paymentMethods}
                        email={email}
                        hasMedicine={hasMedicine}
                        cart={cart}
                        selectedAddress={selectedAddress}
                    />
                </ListProgressItem>
            </ListProgress>
        </CartContext.Provider>
    );
};

export default ShippingContent;
