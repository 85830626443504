import * as React from 'react';
import { useCallback } from 'react';
import { IProductLabels } from 'components/Product/Search';
import { useMutation } from 'redux-query-react';
import Toaster from 'veho-ui/lib/Components/Toaster';
import { IProductResponse } from 'components/Product/Results';
import ControlInput from 'veho-ui/lib/Components/ControlInput';
import ButtonIcon from 'veho-ui/lib/Components/ButtonIcon';
import { debounce, isNumber } from 'lodash';
import { empty } from '../../../helpers/empty';
import { extractErrors } from '../../../helpers/request/extractErrors';
import alterWishlistRequest from 'components/Product/requests/alterWishlistRequest';
import { request } from 'data/requests/request';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';

interface IProps {
    labels: IProductLabels;
    product: IProductResponse;
    isCheckout?: boolean;
    isWishlist?: boolean;
    setProduct: (product) => void;
    quoteId?: string;
    setError?: (error: string) => void;
}

const Alter: React.FunctionComponent<IProps> = (props) => {
    const { labels, product, isCheckout, isWishlist, setProduct, quoteId, setError } = props;

    const [{}, cartRequest] = useMutation((data, id, method) =>
        request({
            method: method,
            type: 'response',
            notApi: false,
            url: isLoggedIn() ? `carts/mine/items/${id}` : `guest-carts/${quoteId}/items/${id}`,
            data,
        }),
    );

    const alterProduct = useCallback(
        debounce((id: any, sku: string, qty: number) => {
            const data: any = {
                cartItem: {
                    sku,
                    qty,
                    quote_id: quoteId,
                },
            };
            cartRequest(data, id, 'PUT').then((response) => {
                if (response.status === 200) {
                    window.dispatchEvent(new CustomEvent('cart-altered'));
                } else {
                    const errors = extractErrors(response);
                    if (errors && errors.error) {
                        if (setError) {
                            setError(errors.error);
                        }
                    }
                }
            });
        }, 500),
        [],
    );

    const [{}, alterWishlistProduct] = useMutation((product) => alterWishlistRequest(product));

    const alterProductWishlist = useCallback(
        debounce((product: IProductResponse, qty: number) => {
            product.buyAmount = qty.toString();
            alterWishlistProduct(product).then((response) => {
                window.dispatchEvent(new CustomEvent('cart-altered'));
                Toaster.addToast({
                    intent: response.status === 200 ? 'success' : 'danger',
                    text: response.body.message,
                    asHtml: true,
                });
            });
        }, 500),
        [],
    );

    const setProductAmount = (amount: number, product: IProductResponse) => {
        const defaultAmount = isCheckout || isWishlist ? 1 : 0;

        if (parseInt(product.buyAmount) === defaultAmount && empty(amount)) {
            return;
        }

        setProduct({
            ...product,
            buyAmount: !empty(amount) ? amount.toString() : defaultAmount.toString(),
        });

        if (isCheckout) {
            alterProduct(product.quoteItemId, product.sku, !empty(amount) ? amount : defaultAmount);
        }
        if (isWishlist) {
            alterProductWishlist(product, !empty(amount) ? amount : defaultAmount);
        }
    };

    return (
        <ControlInput
            value={product.buyAmount.toString()}
            onChange={(e) => {
                setProductAmount(parseInt(e.target.value), product);
            }}
            placeholder={labels.amount}
            className="align-center"
            addOnFront={
                <ButtonIcon
                    icon="minus"
                    onClick={(e) => {
                        e.preventDefault();
                        const v = product.buyAmount ? parseInt(product.buyAmount) : 0;
                        if (isNumber(v) && v - 1 > 0) {
                            setProductAmount(v - 1, product);
                        } else {
                            setProductAmount(0, product);
                        }
                    }}
                />
            }
            addOn={
                <ButtonIcon
                    icon="plus"
                    onClick={(e) => {
                        e.preventDefault();
                        const v = product.buyAmount ? parseInt(product.buyAmount) : 0;
                        if (isNumber(v)) {
                            setProductAmount(v + 1, product);
                        } else {
                            setProductAmount(1, product);
                        }
                    }}
                />
            }
        />
    );
};

export default Alter;
