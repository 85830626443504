import * as React from 'react';

import MainMenu from 'components/ui/MainMenu';
import RenderLink from 'veho-ui/lib/Components/RenderLink/index';
import Cookie from 'components/Cookie';
import RegionSelect from 'components/RegionSelect';
import Messages from 'components/Messages/Messages';
import CustomerDelete from 'components/AccountDelete/CustomerDelete';
import AddressDelete from 'components/AccountDelete/AddressDelete';
import ComponentMapperRecaptcha from 'components/GoogleRecaptcha/ComponentMapperRecaptcha';
import Search from 'components/Product/Search';
import ConsentHandler from 'components/Gdpr/ConsentHandler';
import CartButton from 'components/CartButton';
import CheckoutCart from 'components/Cart/CheckoutCart';
import { CartType } from './enums/CartType';
import WarehouseSelect from 'components/Customer/WarehouseSelect';
import AuthorizationHandler from 'components/Authorization/AuthorizationHandler';
import CheckoutProvider from "components/Checkout/CheckoutProvider";

const componentMapper = (
    maybeComponent: string,
    maybeContent: string,
    maybeConfig,
    ignoreTabs: string[] = [],
): React.Component => {
    return {
        authorization: <AuthorizationHandler config={maybeConfig} />,
        menu: <MainMenu dom={maybeContent} config={maybeConfig} />,
        dropdown: <RenderLink dom={maybeContent} config={maybeConfig} />,
        cookieSelection: <Cookie config={maybeConfig} />,
        regionSelect: <RegionSelect config={maybeConfig} />,
        messages: <Messages />,
        accountDelete: <CustomerDelete config={maybeConfig} />,
        addressDelete: <AddressDelete config={maybeConfig} />,
        recaptcha: <ComponentMapperRecaptcha />,
        vehoProductSearch: <Search config={maybeConfig} />,
        gdprConsentHandler: <ConsentHandler />,
        cart: <CartButton dom={maybeContent} config={maybeConfig} />,
        checkoutCart: <CheckoutCart cartType={CartType.CHECKOUT} config={maybeConfig} />,
        wishlistCart: <CheckoutCart cartType={CartType.WISHLIST} config={maybeConfig} />,
        warehouseSelect: <WarehouseSelect config={maybeConfig} />,
        checkout: <CheckoutProvider />,
    }[(ignoreTabs.includes(maybeComponent) ? '' : maybeComponent) || ''];
};

export default componentMapper;
