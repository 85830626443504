import * as React from 'react';
import { useMutation } from 'redux-query-react';
import setCustomerWarehouseRequest from 'components/Customer/requests/setCustomerWarehouseRequest';
import Toaster from 'veho-ui/lib/Components/Toaster';

interface IProps {
    config: {
        defaultValue: string;
        labels: {
            selectLabel: string;
        };
        options: {
            value: string;
            label: string;
        }[];
    };
}

const WarehouseSelect = (props: IProps) => {
    const { config } = props;
    const [{}, setWarehouseQuery] = useMutation((warehouseCode: string) => setCustomerWarehouseRequest(warehouseCode));

    const selectWarehouse = (value: string) => {
        setWarehouseQuery(value).then((response) => {
            Toaster.addToast({
                intent: response.status === 200 ? 'success' : 'danger',
                text: response.body.message,
                asHtml: true,
            });
        });
    };

    return (
        <>
            <div className="field warehouse-select">
                <label className="label" htmlFor="warehouse-select">
                    <span>{config.labels.selectLabel}</span>
                </label>
                <div className="control">
                    <select
                        onChange={(e) => selectWarehouse(e.target.value)}
                        defaultValue={config.defaultValue}
                        name="warehouse-select"
                        id="warehouse-select"
                        title={config.labels.selectLabel}
                    >
                        {config.options.map((option) => {
                            return <option value={option.value}>{option.label}</option>;
                        })}
                    </select>
                </div>
            </div>
        </>
    );
};

export default WarehouseSelect;
