import * as React from 'react';
import { useCallback, useState } from 'react';
import OverlayConfirm from 'components/overlay/OverlayConfirm';
import Toaster from 'veho-ui/lib/Components/Toaster';
import { QueryResponse } from 'redux-query';

export interface IAccountDeleteProps {
    config: {
        linkText: string;
        popUpTitle: string;
        popUpButtonConfirm: string;
        popUpButtonCancel: string;
    };
    deleteRequest: () => {};
}

const DeleteBase: React.FunctionComponent<IAccountDeleteProps> = (props) => {
    const [showConfirm, setShowConfirm] = useState(false);

    const handleDelete = useCallback(async () => {
        // @ts-ignore
        const response: QueryResponse = await props.deleteRequest();
        Toaster.addToast({
            intent: response.status === 200 ? 'success' : 'danger',
            text: response.body.message,
            asHtml: true,
        });

        if (response.status === 200) {
            // let magento decide what to do with destroyed session customer after setting inactive or address delete
            window.location.reload();
        }
    }, []);

    const handleClose = useCallback(async () => {
        setShowConfirm(false);
    }, []);

    const handleDeleteClick = useCallback(async () => {
        setShowConfirm(true);
    }, []);

    return !showConfirm ? (
        <React.Fragment>
            <span onClick={() => handleDeleteClick()}>{props.config.linkText}</span>
        </React.Fragment>
    ) : (
        <OverlayConfirm
            title={props.config.linkText}
            description={props.config.popUpTitle}
            buttonCancel={props.config.popUpButtonCancel}
            buttonOk={props.config.popUpButtonConfirm}
            onAccept={handleDelete}
            onClose={handleClose}
        />
    );
};

DeleteBase.displayName = 'Account delete';

export default DeleteBase;
