import * as React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import Icon from 'veho-ui/lib/Components/Icon';
import Availability from 'components/Product/Availability';
import { IProductResponse } from 'components/Product/Results';
import { IProductLabels } from 'components/Product/Search';
import Add from 'components/Cart/Buttons/Add';
import Remove from 'components/Cart/Buttons/Remove';
import AddWishlist from 'components/Cart/Buttons/AddWishlist';
import Alter from 'components/Cart/Buttons/Alter';
import { priceRounder } from '../../helpers/priceRounder';
import isLoggedIn from '../../helpers/auth/isLoggedIn';
import RenderHTML from 'veho-ui/lib/Components/RenderHTML';

export interface IProductProps {
    product: IProductResponse;
    currencyCode: string;
    labels: IProductLabels;
    useWithoutVat: boolean;
    deliveryDays: string;
    isCheckout?: boolean;
    isWishlist?: boolean;
    quoteId?: string;
    setError?: (error: string) => void;
}

const ResultRow: React.FunctionComponent<IProductProps> = (props) => {
    const { currencyCode, labels, useWithoutVat, isCheckout, isWishlist, quoteId, setError } = props;
    const [open, setOpen] = useState(false);
    const [product, setProduct] = useState<IProductResponse>(props.product);

    return (
        <React.Fragment>
            <li className={classNames({ replacement: product.isReplacement })}>
                <div
                    className={classNames('list-results__result', { 'intent-danger': product.is404 })}
                    onClick={(e) => {
                        e.preventDefault();
                        if (!product.is404) {
                            setOpen(!open);
                        }
                    }}
                >
                    <Icon
                        width={16}
                        height={16}
                        kind={product.is404 ? 'close' : open ? 'arrow2-up' : 'arrow2-down'}
                        wrapperClassName="list-results__arrow"
                    />
                    <div className="list-results__main">
                        {product.is404 ? (
                            <React.Fragment>
                                <div className="label-primary">{product.sku}</div>
                                <div className="label-secondary">{labels.noProductWithCode}</div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {product.isReplacement ? (
                                    <React.Fragment>
                                        <div className="label-primary">{product.sku}</div>
                                        <div className="label-secondary">
                                            {product.replacementFromSku} {labels.replacementProduct}
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className="label-primary">{product.sku}</div>
                                        <div className="label-secondary">{product.name}</div>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    {!product.is404 && (
                        <React.Fragment>
                            <div className="list-results__delivery">
                                {product.warehouses.length === 0 ||
                                product.stockCommon < parseInt(product.buyAmount) ? (
                                    <React.Fragment>
                                        <div>{labels.toBeOrdered}</div>
                                        <div className="label-secondary">
                                            {product.stockCommon} {labels.inStock}
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div>{labels.deliveryDays.replace('%1', props.deliveryDays)}</div>
                                        <div className="label-secondary">
                                            {product.stockCommon} {labels.inStock}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="list-results__price">
                                <div className="label-primary">
                                    {!isCheckout && product.noDiscountPrice > product.priceWithoutVat && (
                                        <>
                                            <RenderHTML
                                                html={`<del>${priceRounder(
                                                    useWithoutVat
                                                        ? product.noDiscountPrice
                                                        : product.noDiscountPriceWithVat,
                                                )} ${currencyCode}</del>`}
                                                nowrapper={true}
                                            />{' '}
                                        </>
                                    )}
                                    {priceRounder(useWithoutVat ? product.priceWithoutVat : product.priceWithVat)}{' '}
                                    {currencyCode}
                                </div>
                                <div className="label-secondary">
                                    {labels.together}{' '}
                                    {priceRounder(
                                        (useWithoutVat ? product.priceWithoutVat : product.priceWithVat) *
                                            parseInt(product.buyAmount),
                                    )}{' '}
                                    {currencyCode}
                                </div>
                            </div>
                            <div
                                className="list-results__cart"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <Alter
                                    isCheckout={isCheckout}
                                    isWishlist={isWishlist}
                                    setError={setError}
                                    quoteId={quoteId}
                                    setProduct={setProduct}
                                    labels={labels}
                                    product={product}
                                />
                            </div>
                            <div
                                className="list-results__button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                {!isCheckout && <Add isWishlist={isWishlist} product={product} labels={labels} />}
                                {isLoggedIn() && (
                                    <React.Fragment>
                                        {!isWishlist && <AddWishlist product={product} labels={labels} />}
                                    </React.Fragment>
                                )}
                                {(isCheckout || isWishlist) && quoteId && (
                                    <Remove
                                        quoteId={quoteId}
                                        isWishlist={isWishlist}
                                        isCheckout={isCheckout}
                                        setError={setError}
                                        labels={labels}
                                        product={product}
                                    />
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </div>
                {open && <Availability labels={labels} product={product} />}
            </li>
        </React.Fragment>
    );
};

export default ResultRow;
