import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { ICreditLimitData } from 'components/Cart/Interfaces/ICreditLimitData';
import { useMutation } from 'redux-query-react';
import creditLimitGetRequest from 'components/Cart/requests/creditLimitGetRequest';
import isLoggedIn from '../../helpers/auth/isLoggedIn';
import CartBlock from 'veho-ui/lib/Components/CartBlock';

interface IProps {
    config: ICreditLimitData;
    grandTotal?: string;
}

const LoyalityBlock = (props: IProps) => {
    const [config, setConfig] = useState(props.config);
    const [{}, getRequest] = useMutation(() => creditLimitGetRequest());

    const effect = useCallback(async () => {
        const response = await getRequest();
        if (response.body) {
            setConfig(response.body);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('cart-altered', effect);
        return function cleanup() {
            window.removeEventListener('cart-altered', effect);
        };
    }, []);

    return (
        <React.Fragment>
            {isLoggedIn() && parseFloat(config.balance) > 0 && (
                <CartBlock>
                    <h2>{config.labels.creditLimitTitle}</h2>
                    {props.grandTotal && parseFloat(props.grandTotal) > parseFloat(config.balance) && (
                        <div className="cart-callout intent-danger">{config.labels.checkoutSumExceeded}</div>
                    )}
                    <ul className="cart-prices">
                        <li>
                            <span className="label">{config.creditMaxLabel}</span>
                            <span className="value">{config.creditMaxCurrency}</span>
                        </li>
                        <li>
                            <span className="label">{config.balanceLimitLabel}</span>
                            <span className="value">{config.balanceLimitCurrency}</span>
                        </li>
                        <li>
                            <span className="label">{config.balanceLabel}</span>
                            <span className="value">{config.balanceCurrency}</span>
                        </li>
                    </ul>
                    {<h2>{`${config.labels.usedMoney}: ${config.usedAmountCurrency}`}</h2>}
                </CartBlock>
            )}
        </React.Fragment>
    );
};
export default LoyalityBlock;
