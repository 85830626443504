import * as React from 'react';
import { useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useSelector } from 'react-redux';
import { IShippingMethod } from '../../../interfaces/checkout/shipping/IShippingMethod';
import Menu from 'veho-ui/lib/Components/Menu';
import MenuItem from 'veho-ui/lib/Components/MenuItem';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import useErrorState from "components/Checkout/components/error/Error";
import {SHIPPING_METHOD_STEP} from "components/Checkout/components/checkout/Content";
import {ContentLoading} from "components/Checkout/components/loading/ContentLoading";

export interface IProps {
    selectedMethod: IShippingMethod | undefined;
    selectMethod: any | ISelectMethod;
    setCurrentStep: any;
    selectedMethodCode: string | undefined;
}

type ISelectMethod = (method: IShippingMethod) => void;

const mapStateToProps = (state) => {
    return {
        shippingMethods: state.entities.shippingMethods,
    };
};

const ShippingMethods = (props: IProps) => {
    const {selectedMethod, selectMethod, setCurrentStep, selectedMethodCode} = props;
    const { t } = useTranslation();
    const {setError} = useErrorState();

    const selectShippingMethod = (method: IShippingMethod) => {
        setCurrentStep(SHIPPING_METHOD_STEP);
        selectMethod(method);
        window.dispatchEvent(new CustomEvent('shipping-additional-change', {detail: method}));
    };

    const isSelectedShipping = (methodCode: string): boolean => {
        return !!selectedMethod && selectedMethod.methodCode === methodCode;
    };

    const methodsRequest = useRequest(request({type: 'shippingMethods', url: 'shipping/methods', notApi: true}));
    let { shippingMethods } = useSelector(mapStateToProps);

    let methodSelected = false;

    shippingMethods = shippingMethods && shippingMethods.map((method: IShippingMethod, key) => {
        if (
            !selectedMethod &&
            (!methodSelected || (selectedMethodCode && (selectedMethodCode === method.methodCode))) &&
            !method.disabled &&
            !method.extra_data?.disabled
        ) {
            selectShippingMethod(method);
            methodSelected = true;
        }
        let addonText = <React.Fragment key={`addon-${key}`}/>;
        let disabledTextItems = [(<React.Fragment key={`disabled-${key}`}/>)];
        if (method.extra_data?.enable_allowed_times) {
            if (method.extra_data?.disabled_times && method.extra_data?.disabled_times.length) {
                const message = t('checkout.shipping not available');
                disabledTextItems = method.extra_data?.disabled_times.map((disabledItem, index) => (
                    <React.Fragment key={`disabled-${key}-${index}`}>
                        <br/>
                        <small>
                            {disabledItem.date} {disabledItem.times.join('; ')} {message}
                        </small>
                    </React.Fragment>
                ));
            }
            addonText = (
                <React.Fragment key={`addon-${key}`}>
                    <br/>
                    <small>{t('checkout.Available')} {method.extra_data?.availability}</small>
                    {disabledTextItems}
                </React.Fragment>
            );
        }
        if (method.disabled) {
            addonText = (
                <React.Fragment>
                    <br/>
                    <small>
                        {method.disabledReason || t('checkout.Not available for your shopping cart')}
                    </small>
                </React.Fragment>
            );
        }
        return (
            <MenuItem
                key={method.methodCode}
                text={<span>{method.methodLabel}{addonText}</span>}
                addOn={method.discountPrice ? (
                    <React.Fragment>
                        <strong>{(method.discountPrice)}</strong><del>{method.price}</del>
                    </React.Fragment>
                ) : method.price}
                disabled={method.extra_data?.disabled || method.disabled}
                active={isSelectedShipping(method.methodCode)}
                onClick={() => {
                    selectShippingMethod(method);
                }}
                testId={'delivery-method-tab-' + method.methodCode}
            />
        );
    });
    useEffect(() => {
        if (methodsRequest && methodsRequest[0].isFinished && !shippingMethods) {
            setError(true);
        }
    });
    return (
        <Menu layout="inline">
            {!methodsRequest || methodsRequest[0].isPending && (
                <ContentLoading/>
            )}
            {methodsRequest && methodsRequest[0].isFinished && (shippingMethods)}
        </Menu>
    );
};

export default ShippingMethods;
