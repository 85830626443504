import * as React from 'react';
import { useMutation } from 'redux-query-react';
import { useContext, useState } from 'react';
import { IShippingMethod } from '../../../interfaces/checkout/shipping/IShippingMethod';
import {IAddress, ICustomer, IGuestAddress} from '../../../interfaces/checkout/customer/ICustomer';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { IAddressInformation } from '../../../interfaces/checkout/address/IShippingInformationData';
import { ResponseStatusEnum } from '../../../enums/ResponseStatus';
import { isLoggedIn } from '../../../helpers/customer/isLoggedIn';
import Toaster from 'veho-ui/lib/Components/Toaster/index';
import { useRef } from 'react';
import { parseErrorMessage } from '../../../helpers/message/messageManager';
import {CartContext, SHIPPING_DATA_STEP} from "components/Checkout/components/checkout/Content";
import ExistingAddresses from "components/Checkout/components/address/existingAddresses";
import {postRequest} from "components/Checkout/data/requests/postRequest";
import SelectedAddressToPostAddress from "components/Checkout/components/address/selectedAddressToPostAddress";
import NewAddressForm from "components/Checkout/components/checkout/Address/NewAddressForm";
import ExistingAddressForm from "components/Checkout/components/checkout/Address/ExistingAddressForm";
import NewAddressOverlay from "components/Checkout/components/address/NewAddressOverlay";
import ProceedButton from "components/Checkout/components/checkout/ProceedButton";

export interface IProps {
    selectedMethod: IShippingMethod | undefined;
    customer: ICustomer | null;
    proceedAction: any;
    setEmail: any;
    newAddressFields: IGuestAddress | undefined;
    setNewAddressFields: (addressFields: IGuestAddress) => void;
    setSelectedAddress: any;
    email: string | undefined;
    setPaymentMethods: any;
    selectedAddressId: number | null;
    setCurrentStep: any;
    currentStep: number;
}

const AddressBlock = (props: IProps) => {
    const [updateState, setUpdateState] = useState(false);
    const allowQuery = useRef(true);
    const cart = useContext(CartContext);
    const {
        selectedMethod,
        customer,
        proceedAction,
        selectedAddressId,
        setPaymentMethods,
        setEmail,
        email,
        setCurrentStep,
        currentStep,
        newAddressFields,
        setNewAddressFields,
    } = props;
    const [selectedAddress, selectAddress] = useState<IAddress>();
    const [newAddress, selectNewAddress] = useState<IAddress>();
    const { t } = useTranslation();
    const [continueDisabled, setContinueDisabled] = useState(currentStep !== SHIPPING_DATA_STEP);

    const addresses = ExistingAddresses(false, selectedAddress, selectAddress, selectedMethod, customer, selectedAddressId)
        .filter((address) => !address.isBusiness);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [{isFinished}, postData] = useMutation(
        data => postRequest(
            {
                type: 'response',
                url: isLoggedIn ? 'carts/mine/shipping-information' : `guest-carts/${window.quoteIdMask}/shipping-information`,
                data,
                useStoreCode: true,
            }
        )
    );

    const setDisableContinue = (disable: boolean) => {
        if (continueDisabled !== disable) {
            setContinueDisabled(disable);
        }
    };

    const setAllowQuery = (state: boolean) => {
        allowQuery.current = state;
        setUpdateState(!updateState);
    };

    const queryAction = () => {
        if (allowQuery.current && selectedAddress && selectedMethod && !continueDisabled) {
            setAllowQuery(false);
            setDisableContinue(true);
            const address = SelectedAddressToPostAddress(selectedAddress);
            const methods: string[] = selectedMethod.methodCode.split('_');
            if (methods.length < 2) {
                return;
            }
            const addressInformation: IAddressInformation = {
                shipping_address: address,
                billing_address: {...address, saveInAddressBook: 0},
                shipping_method_code: methods.pop() || '',
                shipping_carrier_code: methods.pop() || '',
            };
            if (localStorage.getItem('extension_attributes')) {
                addressInformation.shipping_address.extension_attributes = JSON.parse(localStorage.getItem('extension_attributes') || '{}');
                addressInformation.billing_address.extension_attributes = JSON.parse(localStorage.getItem('extension_attributes') || '{}');
            }
            postData({
                addressInformation,
            }).then((response) => {
                setAllowQuery(true);
                if (response) {
                    setPaymentMethods(response.body ? response.body.payment_methods : []);
                    const responseSuccessful = response && response.status === ResponseStatusEnum.ok;
                    setIsSuccessful(responseSuccessful);

                    if (responseSuccessful) {
                        window.dispatchEvent(new CustomEvent('checkout-step-proceed', {detail: {
                            action: 'add-contact-info',
                            items: cart.cartItems,
                            customer: cart.customer,
                        }}));
                        props.setSelectedAddress(address);
                    }

                    if (!responseSuccessful && response?.body?.message) {
                        Toaster.addToast({
                            intent: 'danger',
                            text: parseErrorMessage(response.body),
                        });
                    }
                }
            });
        }
    };

    useEffect(() => {
        if (isFinished && isSuccessful) {
            setIsSuccessful(false);
            proceedAction();
            window.dispatchEvent(new CustomEvent('shipping-additional-save'));
        }
        if (selectedAddress && selectedAddress.isNew) {
            if (!newAddress) {
                queryAction();
            }
            selectNewAddress(selectedAddress);
        }
        setDisableContinue(currentStep !== SHIPPING_DATA_STEP);
    });
    const newAddressForm = (
        <NewAddressForm
            proceedAction={() => queryAction()}
            selectedMethod={selectedMethod}
            setEmail={setEmail}
            email={email}
            newAddressFields={newAddressFields}
            setNewAddressFields={setNewAddressFields}
            selectedAddress={newAddress}
            customer={customer}
            selectAddress={selectAddress}
        />
    );

    const addressSaveAction = (doProceed: boolean) => {
        window.dispatchEvent(new CustomEvent('new-address-save',  {detail: doProceed}));
    };

    return (
        <div onFocus={() => setCurrentStep(SHIPPING_DATA_STEP)}>
            {(addresses.length === 1 && !addresses.find(address => address.isNew)) || addresses.length > 1 ? (
                <React.Fragment>
                    <ExistingAddressForm
                        proceedAction={() => queryAction()}
                        selectedAddress={selectedAddress}
                        addresses={addresses}
                        showButton={true}
                        title={t('checkout.Select contact address')}
                        continueDisabled={continueDisabled}
                    />
                    {currentStep === SHIPPING_DATA_STEP &&(
                        <NewAddressOverlay
                            title={t('checkout.Shipping Details')}
                            newAddressForm={newAddressForm}
                            proceedAction={() => addressSaveAction(false)}
                        />
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {newAddressForm}
                    <ProceedButton
                        proceedAction={() => addressSaveAction(true)}
                        disabled={continueDisabled}
                    />
                </React.Fragment>
            )}
        </div>
    );
};

export default AddressBlock;
